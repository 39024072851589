.modal {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  margin: 4rem auto;
  background-color: #f1f3f5;
  height: 40vh !important;
  width: 40vw;
  z-index: 3;
  border-radius: 12px;
  padding: 4.4rem;
}

.modal h5 {
  text-align: center;
  font-size: 2.8rem;
}
.modal p {
  text-align: center;
  font-size: 1.8rem;
  width: 80%;
  margin: 0 auto;
  margin-bottom: 2.4rem;
  letter-spacing: 1.1px;
  line-height: 1.6;
}
.modal .modal-name {
  font-size: 2.2rem;
  margin-bottom: 2.2rem;
}

@media (max-width: 768px) {
  html {
    font-size: 50%;
  }
  .modal {
    width: 80%;
    height: 20%;
  }
  .modal p {
    margin-bottom: 0;
  }
}
