/* sssssssssssssssss */

#section-about {
  background-color: #e9ecef;
}
#typewriter {
  margin-top: 4.8rem;
  font-size: 3rem;
  font-weight: 500;
  color: #555;
}
.about {
  grid-template-columns: 1fr 1fr;
  display: grid;
  align-items: center;
  width: 70%;
  height: 100%;
  margin: 0 auto;
  padding-bottom: 2.8rem;
}

.about-skills {
  height: 100%;
}

.about-heading {
  display: block;
  position: relative;
  font-size: 3.8rem;
  font-weight: 500;
  letter-spacing: 1.8px;
  grid-column: 1/-1;
  text-align: center;
}

.about-heading::after {
  content: "";
  display: flex;
  margin: 1.8rem auto;
  margin-bottom: 8rem;
  background-color: #a5d8ff;
  height: 0.5%;
  width: 5%;
  padding: 0.3rem;
}

.about-me {
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  justify-content: center;
}

.about-me-animate {
  opacity: 1;
  animation-name: moveInLeft;
  animation-duration: 1.4s;
}
.about-skills {
  opacity: 0;
}

.about-me img {
  width: 45%;
  height: 46.5rem;
  border-radius: 14px;
  box-shadow: 4px 9px 12px rgba(0, 0, 0, 0.2);
  margin-left: 4rem;
  align-self: center;
  padding: 0.8rem;
}
#about-me-p {
  font-size: 2.8rem;
  color: #666;
  font-weight: 600;
  display: block;
}
.about-me-content {
  opacity: 0;
  grid-column: 2/-1;
  height: 100%;
}

.about-me-content-animate {
  opacity: 1;
  animation-name: moveInRight;
  animation-duration: 1.4s;
}
.about-me-description {
  width: 100%;
  font-size: 2rem;
  letter-spacing: 1px;
  line-height: 1.6;
  font-weight: 400;
  color: #555;
  margin-bottom: 4.2rem;
}
div .about-btn {
  border-bottom-color: black;
}

.about-btn a {
  color: black;
}
@media (max-width: 1500px) {
  .about {
    grid-template-columns: 1fr;
    width: 92%;
  }
  .about-me img {
    display: none;
  }
  .about-heading {
    height: 50%;
    margin-bottom: 0;
    grid-column: 2/3;
  }

  .about-me-content {
    opacity: 1;
  }
  div .about-btn {
    margin: 0 auto;
  }
}

@media (max-width: 768px) {
  .about-me-description {
    grid-column: 1/-1;
  }
  .about-me p {
    width: 95%;
    margin-bottom: 6.4rem;
  }

  .about-heading-p {
    width: 100%;
  }
}
