/* ---01 Typography 


-FONT SIZE SYSTEM (px)
10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98

- Font weight
Default: 400
Medium: 500
Semi-bold: 600
Bold: 700

- line heights
default: 1
Small: 1.05
Medium: 1.2
Paragraph default: 1.6

----Letter spacing
-0.5px
-0.75px
 
---- 02 COLORS
- Primary: #e67e22
- Tints:#fdf2e9
    #fae5d3
- Shades:#cf711f
- Accents:
- Greys: #555
#767676
#333
#6f6f6f

--- 05 SHADOWS

--- 06 BORDER-RADIUS

--- 07 WHITESPACE

-SPACING SYSTEM (px)
 2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128

/ */
html {
  font-size: 62.5%;
  font-family: "Roboto Slab", serif;

  scroll-behavior: smooth;
}
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: #444;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.header-app {
  height: 100vh;
}

.slide-in-left-enter {
  transform: translateX(-100%);
}

.slide-in-left-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: all 200ms;
}

.slide-in-left-exit {
  transform: translateX(0%);
  opacity: 1;
}

.slide-in-left-exit-active {
  transform: translateX(-100%);
  opacity: 0;
  transition: all 200ms;
}
