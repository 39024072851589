.landing-page {
  position: relative;
  height: 100%;
  width: 100%;
  margin: auto;
  align-items: center;
  justify-items: center;
  letter-spacing: 1.2px;
  z-index: 1;
}
/* span{
  color: 
} */
.heading-2 span {
  position: relative;
  color: white !important;
}
.heading-1 span {
  color: #ffe066 !important;
}
.sketch-highlight:before {
  content: "";
  background-color: #4dabf7;
  width: 100%;
  height: 1em;
  position: absolute;
  z-index: -1;
  filter: url(#marker-shape);
  left: -0.25em;
  top: 0.1em;
  padding: 0 0.25em;
  opacity: 45%;
  animation: highlight 2s;
}

@keyframes highlight {
  0% {
    opacity: 0%;
  }
  50% {
    opacity: 10%;
  }
  100% {
    opacity: 45%;
  }
}
.landing-container {
  display: flex;
  position: relative;
  flex-direction: column;
  height: 75%;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.landing-page h1 {
  margin: 2.6rem;
  font-size: 5.2rem;
  letter-spacing: 1.6px;
  text-align: center;
  font-weight: 400;
  line-height: 0.8;
  color: #fff;
}
.landing-page p {
  margin: 0 0 4.2rem 0;
  font-size: 2.4rem;
  text-align: center;
  line-height: 1.05;
  color: #fff;
  font-weight: 400;
}
.landing-page span {
  color: #ffec99;
}

.btn-landing:link,
.btn-landing:active {
  display: inline-block;
  text-transform: uppercase;
  text-decoration: none;
  padding: 1.4rem 4rem;
  border: 2px solid;
  border-radius: 14px;
  transition: all 0.2s;
  font-size: 1.5rem;
  font-weight: 600;
  position: relative;
  animation: moveInBottom 0.5s 0.75s;
  animation-fill-mode: backwards;
  margin-bottom: 9.2rem;
  z-index: 1;
}

.btn-landing:hover {
  transform: translateY(-3px);
  box-shadow: 0 1rem 20px rgba(0, 0, 0, 0.2);
}
.landing-container ion-icon {
  display: block;
  width: 100%;
  border: none;
  position: absolute;
  bottom: 10%;
}
.arrow-down {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: flex-end;
}
/* .landing-container ion-icon {
  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
} */

.btn-landing:active {
  transform: translateY(-1px);
  box-shadow: 0 0.5rem 10px rgba(0, 0, 0, 0.2);
}

.btn-white {
  background-color: transparent;
  color: #ffec99;
}

.btn-landing::after {
  content: "";
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: 12px;
  position: absolute;
  top: -2px;
  left: -2px;
  z-index: -1;
  transition: all 0.4s;
}

.btn-white::after {
  border: 2px solid #ffe066;
  border-radius: 14px;
}

.btn-landing:hover::after {
  transform: scaleX(1.2) scaleY(1.6);
  opacity: 0;
}

.heading-1 {
  animation-name: moveInLeft;
  animation-duration: 1.4s;
}
.heading-2 {
  margin-bottom: 5rem;
  animation-name: moveInRight;
  animation-duration: 1.4s;
}

@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-10rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(10rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(4rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

@media (max-width: 768px) {
  html {
    font-size: 52.5%;
  }
  .landing-page h1 {
    line-height: 1.1;
  }
  .landing-page p {
    line-height: 1.4;
    margin-bottom: 8.2rem;
  }
}
