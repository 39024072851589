.nav-links {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  list-style: none;
  justify-content: center;
  align-items: center;
  gap: 4.4rem;
  color: #444;

  /* z-index: 2; */
}

.nav-links li a:link,
.nav-links li a:visited {
  text-decoration: none;
  color: #fff;
  font-size: 2.4rem;
}

.nav-links li a:hover {
  background-color: #fff9db;
  color: black;
  padding: 0.8rem;
}

@media (max-width: 768px) {
  .nav-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
