.side-drawer {
  position: fixed;
  display: flex;
  align-items: center;
  left: 0;
  top: 0;
  z-index: 100;
  height: 100vh;
  width: 78%;
  background-color: #212529;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
}
