.back {
  background-color: black;
  padding: 4.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding-top: 0;
}
.contact-heading {
  text-align: center;
  font-size: 3.8rem;
  font-weight: 500;
  color: white;
  padding: 2.4rem;
  margin: 0;
}

form {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 65%;
  height: 100%;
  align-items: center;
  background-color: rgb(6, 10, 15);
  border-radius: 14px;
  padding: 4.4rem;
  margin-bottom: 2.2rem;
  color: #e9ecef;
}

form label {
  font-size: 1.8rem;
  margin-bottom: 1.2rem;
}
.invalid-message {
  margin: 0 0 2.2rem 0;
  font-size: 1.4rem;
  color: #fa5252;
}
.input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
}
.input-container input {
  border: 0;
  background-color: #e9ecef;
  border-bottom: 1px solid #868e96;
  border-radius: 6px;
  background-color: #868e96;
}
.input-container input:focus {
  background-color: #999;
  border: 1px solid black;
}
.input-container input,
.input-container textarea {
  margin-bottom: 1.4rem;
  width: 100%;
  padding: 0.8rem;
  background-color: rgb(59, 73, 87);
}
.input-container textarea {
  border-radius: 14px;
  margin-bottom: 3.4rem;
}

.input-container textarea:focus {
  background-color: #999;
  border: 1px solid black;
}
.control-invalid {
  border-left: 3px solid #fa5252 !important;
}
.control-invalid-text {
  background-color: #ffa8a8 !important;
  /* border-radius: 14px 14px 0 0; */
}
.questions-heading {
  text-align: center;
  font-size: 2.4rem;
  color: #adb5bd;
  margin: 0 0 4.2rem 0;
}

.phone-img {
  width: 12.5%;
  margin-bottom: 4.2rem;
}

@media (max-width: 768px) {
  form {
    width: 95%;
    padding: 1.8rem;
  }
  .input-container {
    width: 95%;
    padding: 1.2rem;
  }

  .phone-img {
    width: 55%;
  }

  .contact-heading {
    margin: 0 auto;
  }
}
